body {
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 100vh;
}

.helpful-updates {
  font-family: Arial, sans-serif;
  color: #333;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

header {
  position: absolute;
  top: 0;
  width: 100%;
  background-color: #2c3e50;
  padding: 0 20px 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 49px;
  transition: all 0.2s ease-in-out;
}

header.collapsed {
  background-color: #DEDEDE;
  /* height: 35px; */
}

header.collapsed nav ul {
  display: none;
}

nav {
  display: flex;
  align-items: center;
}

nav ul {
  list-style-type: none;
  display: flex;
  gap: 20px;
}

nav ul li {
  color: white;
  cursor: pointer;
}

.menu-toggle {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
}

header.collapsed .menu-toggle {
  color: black;
}

.content {
  padding-top: 49px;
  height: 100vh;
  display: flex;
  flex: 1;
  overflow: hidden;
}

.aside {
  background-color: #fff;
  padding: 10px 0 0 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

button.aside-tab {
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aside ul li {
  margin-bottom: 10px;
  cursor: pointer;
}

main {
  flex: 1;
  padding: 20px 20px 0 20px;
  background-color: rgb(248, 249, 251);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.tabs {
  position: absolute;
  left: 0;
  transform: translateX(-100%);
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-bottom: 20px;
  width: fit-content;
}

.tab {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 10px;
  cursor: pointer;
  position: relative;
  position: relative;
}

.tab::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

.tab::after {
  background-color: var(--tab-color);
}

.tab:hover {
  background-color: #eee;
  /* border: none !important; */
  /* padding-bottom: 7px; */
}

.tab.selected {
  background-color: #fff;
}

.tab img {
  width: 60px;
  border-radius: 50%;
  display: block;
}

.image-holder {
  position: relative;
  height: fit-content;
}

.notification {
  background-color: #e74c3c;
  color: white;
  border-radius: 50%;
  padding: 2px 8px;
  font-size: 12px;
  position: absolute;
  top: 0px;
  right: 0px;
  transform: translateX(30%);
}

.tabs-updates-container {
  flex: 1;
  position: relative;
  margin: 0 0 0 100px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100% - 60px);
}

.updates-container {
  flex: 1;
  background-color: #fff;
  padding: 10px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.updates-container h1 {
  font-weight: normal;
  font-size:  1.5rem;
}

.updates-skin {
  flex: 1;
  overflow-y: scroll;
}

.update {
  margin: 0 0 20px 0;
}

.update-header {
  background-color: #F2F3F5;
  margin: 0 0 10px 0;
  padding: 8px 60px 8px 40px;
  display: flex;
  justify-content: space-between;
}

.update-header div:nth-child(1) {
  font-weight: bold;
}

.update-header div:nth-child(2) {
  color: #666;
}

.update p {
  color: #666;
  margin-bottom: 10px;
}

.update ul {
  padding-left: 80px;
}

@media (max-width: 768px) {
  nav ul {
    display: none;
  }
  
  .menu-toggle {
    display: block;
  }
  
  .content {
    flex-direction: column;
  }
  
  .aside {
    width: 100%;
  }
}

.dashboard-title {
  margin: 0 auto;
  width: fit-content;
}

