:root {
  --light-gray: #BCBCBC33;
  --gray: rgb(144, 149, 159);
  --dark-gray: #1F2B41;
  --light-green: rgb(174, 241, 121);
  --green: #4FAF38;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  color: var(--dark-gray);
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  transition: background 0.3s;
}

h1 {
  font-size: 26px;
  font-weight: 600;
  margin: 5px 0;
}

/* auth pages */
.justify-right {
  display: flex;
  justify-content: flex-end;
}

.flex-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.full-screen {
  height: 100vh;
  width: 100%;
}
.bg-gray {
  background-color: var(--gray);
}
.signup-container {
  position: relative;
  background-color: white;
  padding: 30px 50px 40px 50px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  width: 350px;
}
.signin-link {
  margin: 5px 0 30px 0 !important;
}
.signin-link a {
  color: var(--green);
  text-decoration: none;
}
input {
  width: 100%;
  padding: 10px;
  margin: 2px 0 15px 0;
  border-radius: 4px;
  background-color: var(--light-gray);
  border: none;
  box-sizing: border-box;
}
input:focus {
  border: 1px solid var(--green);
  padding: 9px;
  outline: none;
}
input::placeholder {
  color: #999;
}
.text-sm, .text-sm * {
  font-size: 12px;
}
.text-gray, .text-gray * {
  color: #535458;
}
.password-hint {
  margin: -8px 0 20px 0;
  color: #999;
}
.action-form-btn {
  width: 100%;
  padding: 10px;
  background-color: var(--light-green);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  background-color: var(--light-green);
}
.action-form-btn:hover {
  background-color: var(--green);
}
.close-btn {
  position: absolute;
  padding: 10px;
  top: 15px;
  right: 15px;
  cursor: pointer;
  font-size: 40px;
  line-height: 30px;
  font-weight: 400;
  color: #D9D9D9;
}
.sign-in-btn, .sign-up-btn {
  margin-bottom: 15px;
}
.split-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
label {
  display: flex;
  align-items: center;
}
.remember-me-checkbox {
  margin: 0 15px 0 0;
  padding: 0;
  background-color: red !important;
}
.need-help-link {
  text-decoration: none;
}

input[type="checkbox"] {
  filter: grayscale(100%);
}

@media screen and (max-width: 600px) {
  .signup-container {
    width: 95%;
    padding: 30px 20px 40px 20px;
  }
}

.error-text {
  color: red;
}

@media screen and (max-width: 600px) {
  .center-mobile {
    width: fit-content;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  
}